/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import fullLogo from '../images/stoopFullLogo.png'

export default function Login () {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [resetEmail, setResetEmail] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [isButtonInactive, setIsButtonInactive] = useState(true)
    const [hasForgotten, setHasForgotten] = useState(false)

    function handleEmailFieldChange(event) {
        setEmail(event.target.value)
        setAlertMessage("")
        setIsButtonInactive(false)
    }

    function handleResetEmailFieldChange(event) {
        setResetEmail(event.target.value)
        setAlertMessage("")
        setIsButtonInactive(false)
    }

    function handlePasswordFieldChange(event) {
        setPassword(event.target.value)
        setAlertMessage("")
        setIsButtonInactive(false)
    }

    function toggleMemory() {
        setHasForgotten(!hasForgotten)
    }

    function logIn(event) {
        event.preventDefault()
        document.getElementById('login-button').classList.add('is-loading')
        setIsButtonInactive(true)
        if (email === ""  || password === "") {
            setAlertMessage("Both fields are required.")
            document.getElementById('login-button').classList.remove('is-loading')
            setIsButtonInactive(false)
            return
        }
        firebase.auth().signInWithEmailAndPassword(email, password).catch(error => {
            setAlertMessage("Nope. Try again or click forgot password?")
            document.getElementById('login-button').classList.remove('is-loading')
            setIsButtonInactive(false)
        })
    }

    function requestResetLink(event) {
        event.preventDefault()
        document.getElementById('request-reset-button').classList.add('is-loading')
        setIsButtonInactive(true)
        if (resetEmail === "") {
            setAlertMessage("Please enter an email address")
            document.getElementById('request-reset-button').classList.remove('is-loading')
            setIsButtonInactive(false)
            return
        }
        firebase.auth().sendPasswordResetEmail(resetEmail).then(() => {
            setAlertMessage("If that email matches, we'll send a password reset link!")
            document.getElementById('request-reset-button').classList.remove('is-loading')
            document.getElementById('reset-alert-message').classList.remove('is-danger')
        }).catch((error) => {
            if(error.code === 'auth/user-not-found') {
                setAlertMessage("If that email matches, we'll send a password reset link!")
                document.getElementById('request-reset-button').classList.remove('is-loading')  
                document.getElementById('reset-alert-message').classList.remove('is-danger')
            } else {
                setAlertMessage(error.message)
                setIsButtonInactive(false)
                document.getElementById('request-reset-button').classList.remove('is-loading')
            } 
        })
    }

    return (
        <div className="login-container">
            <img src={fullLogo} alt="Stoop full logo"/>
            {!hasForgotten &&
                <form onSubmit={logIn}>
                    <label className='form-label'>External email (not your Stoop one)</label>
                    <input type="text" placeholder="Email" className='form-input' value={email} onChange={handleEmailFieldChange} autoCorrect="off" autoCapitalize="none" />                    
                    <label className='form-label'>Password</label>
                    <input type="password" placeholder="Password" className='form-input' value={password} onChange={handlePasswordFieldChange} />                    
                    <button id="login-button" disabled={isButtonInactive} type="submit" className="primary-button">Log into Stoop web beta</button>
                    <p className="form-help">{alertMessage}</p>
                    <a onClick={toggleMemory} style={{display:'block', marginTop:'12px'}}>Forgot password?</a>
                </form>
            }
            {hasForgotten &&
                <form onSubmit={requestResetLink}>
                    <label className='form-label'>Email address</label>
                    <input type="text" placeholder="Email" className='form-input' value={resetEmail} onChange={handleResetEmailFieldChange} autoCorrect="off" autoCapitalize="none" />                    
                    <button id="request-reset-button" disabled={isButtonInactive} type="submit" className="primary-button">Send password reset email</button>
                    <p id="reset-alert-message" className="form-help">{alertMessage}</p>
                    <a onClick={toggleMemory} style={{display:'block', marginTop:'12px'}}>Back to login</a>
                </form>
            }
        </div>
    )
}