import React, {useEffect, useState} from 'react'
import firebase from "firebase/app"
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import Loading from './components/Loading'
import Login from './components/Login'
import AppLayout from './components/AppLayout'

firebase.initializeApp({
    apiKey: "AIzaSyAJ8WKDXaZ4hzjOPpmFRw8JAxFB9n4O3p4",
    authDomain: "stoop-id.firebaseapp.com",
    databaseURL: "https://stoop-id.firebaseio.com",
    projectId: "stoop-id",
    storageBucket: "stoop-id.appspot.com",
    messagingSenderId: "959006018694"
})

export default function App () { 
    const [userId, setUserId] = useState(null)
    const [userDocSnap, setUserDocSnap] = useState(null)
    const [subscriptionsData, setSubscriptionsData] = useState(null)

    //listen for login changes
    useEffect(() => {
        return firebase.auth().onAuthStateChanged(user => {
            if (user) {
                setUserId(user.uid)
            } else {
                setUserDocSnap("none")
            }
        })
    },[])

    // listen to userDocSnap
    useEffect(() => {
        if (userId) {
            return firebase.firestore().collection('users').doc(userId).onSnapshot(u => {
                setUserDocSnap(u)
            })
        }
    },[userId])

    // listen to SubscriptionsDocSnap (and for now, filter out channels; not supported in web)
    useEffect(() => {
        if (userDocSnap && userDocSnap !== "none") {
            return userDocSnap.ref.collection('subscriptions').orderBy('newsletterName').onSnapshot(subscriptionsQuerySnap => {
                setSubscriptionsData(subscriptionsQuerySnap.docs)
                // setSubscriptionsData(subscriptionsQuerySnap.docs.filter(i => !i.data().type || i.data().type !== "channel"))
            })
        }
    },[userDocSnap])

    return (
        <div>
            {(!userDocSnap || !subscriptionsData) && userDocSnap !== "none" && <Loading />}
            {userDocSnap === "none" && <Login />}
            {userDocSnap && subscriptionsData && <AppLayout userDocSnap={userDocSnap} subscriptionsData={subscriptionsData} />}
        </div>
    )
}