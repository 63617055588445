/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'

export default function Merge ({userDocSnap}) {
    const [urlTextField, setUrlTextField] = useState("")
    const [idTextField, setIdTextField] = useState("")
    const [infoText, setInfoText] = useState("")

    function handleUrlFieldChange (e) {
        setUrlTextField(e.target.value)
    }

    function handleIdFieldChange (e) {
        setIdTextField(e.target.value)
    }

    async function storeLogo() {
        try {
            if (urlTextField !== "" && idTextField !== "") {
                const response = await fetch('https://us-central1-stoop-id.cloudfunctions.net/newNewsletterLogoFromUrl', {
                    method: 'POST',
                    headers: {'Content-Type' : 'application/json'},
                    body: JSON.stringify({
                        newsletterId: idTextField,
                        url: urlTextField
                    })
                })
                console.log("response:", response)
                const responseJson = await response.json()
                setInfoText(JSON.stringify(responseJson))
            } else {
                setInfoText("Both fields are required")
            }
        } catch (error) {
            setInfoText("error: ", JSON.stringify(error))
        }
    }

    return (
        <div className="inbox-container">
            <div className="inbox-header-container">
                <h1 className="title-1 cutoff-able-text">Store a logo</h1>
            </div>
            {userDocSnap.id === 'MuE234BbJPVwjFLwTgB94KcbAso2' &&
                <div className="inbox-body-container" style={{maxWidth:'400px'}}>
                    <label className="form-label">Gimme dat URL</label>
                    <input type="text" onChange={handleUrlFieldChange} className="form-input" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" />
                    <label className="form-label">Newsletter ID</label>
                    <input type="text" onChange={handleIdFieldChange} className="form-input" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" />
                    <button id="login-button" onClick={storeLogo} type="submit" className="primary-button">Store</button>
                    <p style={{marginTop:"20px"}}>{infoText}</p>
                </div>
            }
        </div>
    )
}