import React from 'react'

export default function Logo ({image, text, size, character}) {
    let placeholderLetter = "X"

    if (text) {
        try {
            placeholderLetter = text.slice(0,1).toUpperCase()
            if (text.slice(0,4).toLowerCase() === "the ") {
                placeholderLetter = text.slice(4,5).toUpperCase()
            }        
        } catch (e) {
            // just leave as X
        }
    }

    // small circle is 24, medium circle is 40, large circle is 32 on mobile and 44 otherwise

    let classString = "medium circle"
    if (size) {
        classString = `${size} circle`
    }
    
    if (!image || image === "") {
        return (<div className={`placeholder-logo ${classString}`} >{character || placeholderLetter}</div>)
    } return (<img className={classString} src={image} alt={"newsletter logo"} />)
}