/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import firebase from "firebase/app"
import InfoRow from './InfoRow'
import { XCircle } from 'react-feather';

export default function Merge ({userDocSnap}) {
    const [items, setItems] = useState([])
    const [needsReload, setNeedsReload] = useState([])
    const [deleted, setDeleted] = useState([])
    const [errorMessage, setErrorMessage] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        firebase.firestore().collection('merged').limit(25).orderBy('when', 'desc').get().then(mergedQuerySnap => {
            const supportingData = []
            for (const d of mergedQuerySnap.docs) {
                const from = firebase.firestore().doc(`newsletters/${d.data().from}`).get()
                const to = firebase.firestore().doc(`newsletters/${d.data().to}`).get()
                const user = firebase.firestore().doc(`users/${d.data().user}`).get()
                Promise.all([from, to, user]).then(result => {
                    supportingData.push({from: result[0], to: result[1], user: result[2], when: d.data().when, id: d.id})
                })
            }
            setItems(supportingData)
        })
    },[])

    function addMergeUsingHostnameWildcard (id) {
        firebase.firestore().doc(`newsletters/${id}`).update({mergeUsingHostnameWildcard: true})
        setNeedsReload([...needsReload, id])
    }

    async function globalMerge(fromId, toId, requestId) {
        try {
            setLoading(true)
            await firebase.firestore().doc(`newsletters/${fromId}`).update({pointsTo: toId})
            await deleteItem(requestId)
            setLoading(false)
        } catch (error) {
            setErrorMessage(error.message)
            setLoading(false)
        }
    }

    async function deleteItem(id) {
        try {
            await firebase.firestore().doc(`merged/${id}`).delete()
            setDeleted([...deleted, id])
            setLoading(false)
        } catch (error) {
            setErrorMessage(error.message)
            setLoading(false)
        }
    }

    return (
        <div className="inbox-container">
            <div className="inbox-header-container">
                <h1 className="title-1 cutoff-able-text">Merge queue</h1>
                <h2 className="subtitle-1 cutoff-able-text">{errorMessage}</h2>
            </div>
            {userDocSnap.id === 'MuE234BbJPVwjFLwTgB94KcbAso2' &&
                <div className="inbox-body-container" style={{padding:'12px'}}>
                    {items.map((item, index) => !deleted.includes(item.id) &&
                        <div className="merge-queue-item">
                            <a href={`mailto:${item.user.data().email}?subject=A note from Stoop&body=Hi there!%0A%0AYou recently merged some newsletters in Stoop. Just so you don't become frustrated by this, I wanted to let you know that any new issues from ${item.from.data().name} will re-create it as separate newsletter.%0A%0AThe merge feature is useful when two newsletters really are the same, but Stoop incorrectly categorizes them as different. In that case, Stoop 'learns' and keeps them together from then on. However, when newsletters are in fact different, Stoop will continue to try to separate them as new issues arrive.%0A%0AYou can create a 'folder' in Stoop if you'd like to have one spot to access issues from several different newsletters. That does happen to be a premium feature -- I'm not trying to upsell you, just wanted to explain all of this!%0A%0ATim`}><h2 className="title-4">{item.user.data().email}</h2></a>
                            <p className="subtitle-2">{new Date(item.when.toDate()).toDateString()}</p>
                            <div className="merge-queue-from">
                                <InfoRow
                                    image={item.from.data().logo_URL}
                                    text={item.from.data().name}
                                />
                                <p><span className="emphasized">Searchable: </span>{(item.from.data().isSearchable && "yes") || "no"}</p>
                                <p><span className="emphasized">Publisher: </span>{item.from.data().name}</p>
                                <p><span className="emphasized">Senders: </span>{item.from.data().sender.map((sender, index) => <span>{sender}{index !== item.from.data().sender.length - 1 && ", "}</span>)}</p>
                                <p><span className="emphasized">Created: </span>{new Date(item.from.data().createdAt.toDate()).toDateString()}</p>
                                <a target="_blank" rel="noopener noreferrer" href={`https://console.firebase.google.com/u/0/project/stoop-id/database/firestore/data~2Fnewsletters~2F${item.from.id}`}>{item.from.id}</a>
                                {!item.from.data().mergeUsingHostnameWildcard && item.from.data().hostname !== "" && <a className="link-button-no-width" onClick={() => addMergeUsingHostnameWildcard(item.from.id)}>{`Use ${item.from.data().hostname} wildcard for merge`}</a>}
                                {item.from.data().mergeUsingHostnameWildcard && <p className="emphasized">{`Using ${item.from.data().hostname} wildcard for merge`}</p>}
                            </div>
                            <div>
                                <InfoRow
                                    image={item.to.data().logo_URL}
                                    text={item.to.data().name}
                                />
                                <p><span className="emphasized">Searchable: </span>{(item.to.data().isSearchable && "yes") || "no"}</p>
                                <p><span className="emphasized">Publisher: </span>{item.to.data().name}</p>
                                <p><span className="emphasized">Senders: </span>{item.to.data().sender.map((sender, index) => <span>{sender}{index !== item.to.data().sender.length - 1 && ", "}</span>)}</p>
                                <p><span className="emphasized">Created: </span>{new Date(item.to.data().createdAt.toDate()).toDateString()}</p>
                                <a target="_blank" rel="noopener noreferrer" href={`https://console.firebase.google.com/u/0/project/stoop-id/database/firestore/data~2Fnewsletters~2F${item.to.id}`}>{item.to.id}</a>
                            </div>
                            {(needsReload.includes(item.from.id) && <p>Need reload to ensure wildcard flag is set</p>)|| <a className="primary-button" style={{width:'200px'}} onClick={() => globalMerge(item.from.id, item.to.id, item.id)}>{(loading && "Loading...") || "Make it global"}</a>}
                            <a className="icon-button merge-queue-delete" style={{alignSelf:'flex-end'}} onClick={() => deleteItem(item.id)}><XCircle /></a>
                        </div>
                    )}
                </div>
            }
        </div>
    )
}