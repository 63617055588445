import React from 'react'
import emptyStateLogo from '../images/stoopEmptyState.png'

export default function NotFound () {

    return (
        <div style={{padding:"1.5rem", height:"100vh", overflow:"auto", display:"flex", justifyContent:"center", alignItems:"center"}}>
            <div style={{textAlign:"center"}}>
                <img src={emptyStateLogo} alt="Stoop logo" />
                <p style={{color:"#b3b3b3", marginTop:"12px"}}>Nothing matches that URL</p>
            </div>
        </div>
    )
}