/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import firebase from "firebase/app"
import {Heart, CheckCircle, RotateCcw} from "react-feather"
import {getHumanReadableDate, moveFromInbox} from '../AppFunctions'
import Logo from './Logo'
import InfoRow from './InfoRow'

export default function InboxRow (props) {
    const [instaSaveStatus, setInstaSaveStatus] = useState(0)
    const previewText = props.issue.data().plainText && props.issue.data().plainText.slice(0,140) || ""
    
    let isLink = false
    if (previewText.slice(0,4) === 'http') {
        isLink = true
    }

    function setCurrentIssueActive () {
        if (isLink) {
            window.open(props.issue.data().plainText)
        } else {
            props.setActiveIssue(props.issue)
        }
    }

    function toggleSaveIssue () {
        if (instaSaveStatus === 1 || (props.issue.data().saveType && props.issue.data().saveType !== "")) {
            setInstaSaveStatus(-1)
            props.issue.ref.update({saveType: ''})            
        } else {
            setInstaSaveStatus(1)
            props.issue.ref.update({saveType: props.issue.data().channelId && 'video' || 'mail', savedAt: firebase.firestore.FieldValue.serverTimestamp()})            
        }
    }

    function archiveIssue () {
        props.setInstaHidden(props.issue.id)
        moveFromInbox(props.userId, props.issue.data().firestoreNewsletterId, props.issue.id, "archive")
    }

    function markIssueUnread () {
        props.setInstaHidden(props.issue.id)
        moveFromInbox(props.userId, props.issue.data().firestoreNewsletterId, props.issue.id, props.issue.data().channelId && 'inbox-video' ||  'inbox')
    }

    let classText = "inbox-row"
    if (props.selectedRow - 1 === props.index) {
        classText = classText+" selected"
    }
    if (props.issue.data().channelId) {
        classText = classText+" inbox-row-video"
    }

    return (
        <div className={classText}>
            {props.title !== 'Unread' && props.issue.data().flag === "inbox" && <div className="unread-indicator"></div>}
            <div className="hide-when-mobile flex-group cutoff-able-text" onClick={setCurrentIssueActive}>
                {props.issue.data().channelId && 
                    <div style={{position:"relative", overflow:"hidden", height:'60px', display:"flex", alignItems:"center"}}>
                        <img className="video-thumbnail" src={props.issue.data().thumbnailUrl} alt="video thumbnail" />
                        <div style={{position:"absolute", bottom:0, backgroundColor:'red', height:4, width: props.issue.data().duration && `${(props.issue.data().progressSeconds || 0) / (props.issue.data().duration) * 100}%` || 0}}></div>
                    </div>
                }
                {props.subscription && <Logo image={props.subscription.data().logo_URL} text={props.subscription.data().newsletterName} size={"small"} color={props.subscription.data().color} character={props.subscription.data().character} />}                
                <span style={{marginRight:"8px"}}>{props.issue.data().newsletterName || props.issue.data().channelName}</span>
                <span className="inbox-row-text">
                    <a>{props.issue.data().subject || props.issue.data().title}</a>
                    {!isLink && <span style={{marginLeft:"8px"}}>{previewText}</span>}
                </span>
            </div>
            <span className="hide-when-mobile subtitle-2" style={{marginLeft:"8px"}}>{getHumanReadableDate(props.issue.data().date.toDate())}</span>
            <div className="hover-buttons"> 
                <a className="icon-button" onClick={toggleSaveIssue} style={{marginLeft:'6px'}}>
                    {(instaSaveStatus === 1 || (props.issue.data().saveType && props.issue.data().saveType !== "")) && <Heart color="red" />}
                    {(instaSaveStatus === -1 || !props.issue.data().saveType || props.issue.data().saveType === "")  && <Heart />}
                </a>
                {props.issue.data().flag.includes('inbox')  && <a className="icon-button" onClick={archiveIssue} style={{marginLeft:'6px'}}><CheckCircle /></a>}
                {!props.issue.data().flag.includes('inbox') && <a className="icon-button" onClick={markIssueUnread} style={{marginLeft:'6px'}}><RotateCcw /></a>}
            </div>
            <div className="show-when-mobile" onClick={setCurrentIssueActive} style={{flexDirection:"column", width:"100%"}}>
                {props.issue.data().channelId &&
                <div>
                    <div className="video-thumbnail-container">
                        <img className="video-thumbnail" src={props.issue.data().thumbnailUrl} alt="video thumbnail" />
                    </div>
                    <div style={{backgroundColor:'red', height:4, width: props.issue.data().duration && `${(props.issue.data().progressSeconds || 0) / (props.issue.data().duration) * 100}%` || 0}}></div>
                </div>
                }
                <div className={props.issue.data().channelId && "video-card-info-container" || ""}>
                    {props.subscription &&
                        <div className="mobile-inbox-cards-info-row">
                            <InfoRow
                                image={props.subscription.data().logo_URL}
                                text={props.subscription.data().newsletterName}
                                color={props.subscription.data().color}
                                character={props.subscription.data().character}
                            />
                        </div>
                    }
                    <div className="title-5">{props.issue.data().subject || props.issue.data().title}</div>
                    <div className="subtitle-5">
                        {`${getHumanReadableDate(props.issue.data().date.toDate())}`}
                        {!props.issue.data().channelId && <span style={{marginRight:"8px", marginLeft:"8px"}}>•</span> || ""}
                        {!props.issue.data().channelId && (props.issue.data().readingEstimateMins + ' min') || ""}
                    </div>
                </div>
            </div>
        </div>
    )
}