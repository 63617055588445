/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, {useState, useEffect} from 'react'
import firebase from "firebase/app"
import {Link} from 'react-router-dom'
import YouTube from 'react-youtube'
import InboxRow from './InboxRow'
import emptyStateLogo from '../images/stoopEmptyState.png'
import {Bookmark, Info, ChevronLeft, X, ChevronRight, CheckCircle, MoreHorizontal, RotateCcw, Sliders, Menu, Mail, Youtube, Filter} from "react-feather"
import {getHumanReadableDate, moveFromInbox} from '../AppFunctions'
import InfoRow from './InfoRow'
import Logo from './Logo'

export default function InboxPage ({userDocSnap, subscriptionsData, issuesQuery, setIssuesQuery, activeIssue, setActiveIssue, folders, goToFolder}) {
    const [loading, setLoading] = useState(true)
    const [infoVisible, setInfoVisible] = useState(false)
    const [justSaved, setJustSaved] = useState(false)
    const [justUnsaved, setJustUnsaved] = useState(false)
    const [saveType, setSaveType] = useState(null)
    const [selectedRow, setSelectedRow] = useState(0)
    const [searchingFolders, setSearchingFolders] = useState(false)
    const [folderQuery, setFolderQuery] = useState("")
    const [selectedIdForNavigation, setSelectedIdForNavigation] = useState("")
    const [madeUnread, setMadeUnread] = useState(false)
    const [instaHidden, setInstaHidden] = useState([])
    const [moreType, setMoreType] = useState(null)
    const [issues, setIssues] = useState([])
    const [lastSeen, setLastSeen] = useState(null)
    const [unreadCount, setUnreadCount] = useState(0)
    const [showingModal, setShowingModal] = useState(false)
    const [newFolderName, setNewFolderName] = useState('')
    const [newPublisherName, setNewPublisherName] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [updating, setUpdating] = useState(false)
    const [vid, setVid] = useState(null)
    
    const fetchBatchSize = 30 // 5 in dev; 30 (?) for production *****************
    const characterDictionary = {"Unread": '📨', "Unwatched": '📺', "History": '⌛️', "Saved": '❤️'}

    useEffect(() => {
        setLoading(true)
        setInstaHidden("")
        let fetchLimit = fetchBatchSize + issuesQuery.more
        const stoopPremium = userDocSnap.data().stoopPremium || false
        if (issuesQuery.reset) {
            setLoading(false)
        } else if (issuesQuery.newsletterId && issuesQuery.more > 0) { //specific subscription including read/watched
            fetchLimit = issuesQuery.more
            if (!stoopPremium) {
                fetchLimit = Math.min(fetchLimit, 10)
            }
            return userDocSnap.ref.collection('inbox').where("firestoreNewsletterId","==",issuesQuery.newsletterId).orderBy('date', 'desc').limit(fetchLimit).onSnapshot(issuesQuerySnap => {
                if (issuesQuerySnap.size === fetchLimit) {
                    if (!stoopPremium && fetchLimit === 10) {
                        setMoreType('premium')
                    } else {
                        setMoreType('more')
                    }
                } else {
                    setMoreType(null)
                }
                setIssues(issuesQuerySnap.docs)
                setUnreadCount(issuesQuerySnap.docs.filter(i => i.data().flag.slice(0,5) === 'inbox').length)
                setLoading(false)
            })
        } else if (issuesQuery.newsletterId) { // specific subscription unread/unwatched
            if (!stoopPremium) {
                fetchLimit = Math.min(fetchLimit, 10)
            }
            return userDocSnap.ref.collection('inbox').where("flag","==",issuesQuery.flag).where("firestoreNewsletterId","==",issuesQuery.newsletterId).orderBy('date', 'desc').limit(fetchLimit).onSnapshot(issuesQuerySnap => {
                setUnreadCount(issuesQuerySnap.size)
                if (issuesQuerySnap.size === fetchLimit) {
                    if (!stoopPremium && fetchLimit === 10) {
                        setMoreType('premium')
                    } else {
                        setMoreType('more')
                    }
                } else {
                    setMoreType('more')
                }
                setIssues(issuesQuerySnap.docs)
                setLoading(false)
            })
        // folders do not support channels/videos. Only newsletters. Either that or they dont support showing unread only.    
        } else if (issuesQuery.title === "Unread" || issuesQuery.title === "Unwatched") {
            return userDocSnap.ref.collection('inbox').where("flag","==",issuesQuery.flag).orderBy('date', 'desc').limit(fetchLimit).onSnapshot(issuesQuerySnap => {
                if (issuesQuerySnap.size === fetchLimit) {
                    setMoreType('more')
                } else {
                    setMoreType(null)
                }
                setUnreadCount(issuesQuerySnap.docs.filter(i => i.data().flag.slice(0,5) === 'inbox').length)
                setIssues(issuesQuerySnap.docs)
                setLoading(false)
            })
        } else if (issuesQuery.title === "Saved") {
            return userDocSnap.ref.collection('inbox').where("saveType","==",saveType).orderBy('savedAt', 'desc').limit(fetchLimit).onSnapshot(issuesQuerySnap => {
                if (issuesQuerySnap.size === fetchLimit) {
                    setMoreType('more')
                } else {
                    setMoreType(null)
                }
                setIssues(issuesQuerySnap.docs)
                setUnreadCount(0)
                setLoading(false)
            })
        } else if (issuesQuery.title === "History") {
            if (!stoopPremium) {
                fetchLimit = Math.min(fetchLimit, 50)
            }
            return userDocSnap.ref.collection('inbox').where("flag","==",'archive').orderBy('archivedAt', 'desc').limit(fetchLimit).onSnapshot(issuesQuerySnap => {
                if (issuesQuerySnap.size === fetchLimit) {
                    if (!stoopPremium && fetchLimit === 50) {
                        setMoreType('premium')
                    } else {
                        setMoreType('more')
                    }
                } else {
                    setMoreType(null)
                }
                setUnreadCount(0)
                setIssues(issuesQuerySnap.docs)
                setLoading(false)
            })
        } else if (issuesQuery.title && issuesQuery.more > 0) { // folder query including read/watched
            fetchLimit = issuesQuery.more
            return userDocSnap.ref.collection('inbox').where("folder","==",issuesQuery.title).orderBy('date', 'desc').limit(fetchLimit).onSnapshot(issuesQuerySnap => {
                if (issuesQuerySnap.size === fetchLimit) {
                    setMoreType('more')
                } else {
                    setMoreType(null)
                }
                setIssues(issuesQuerySnap.docs)
                setUnreadCount(issuesQuerySnap.docs.filter(i => i.data().flag === 'inbox').length)
                setLoading(false)
            })
        } else if (issuesQuery.title) { // folder query unread/unwatched only
            return userDocSnap.ref.collection('inbox').where("flag","==",'inbox').where("folder","==",issuesQuery.title).orderBy('date', 'desc').limit(fetchLimit).onSnapshot(issuesQuerySnap => {
                setUnreadCount(issuesQuerySnap.size)
                if (issuesQuerySnap.size === fetchLimit) {
                    setMoreType('more')
                } else {
                    setMoreType('more')
                }
                setIssues(issuesQuerySnap.docs)
                setLoading(false)
            })
        }
    },[userDocSnap, issuesQuery, saveType])

    useEffect(resetState,[issuesQuery.title])

    useEffect(() => {
        const today = new Date()
        const mostRecentFirstDailyOpen = new Date(userDocSnap.data().mostRecentFirstDailyOpen && userDocSnap.data().mostRecentFirstDailyOpen.toDate() || 0)
        if(today.toDateString() !== mostRecentFirstDailyOpen.toDateString()) {
            userDocSnap.ref.update({mostRecentFirstDailyOpen: firebase.firestore.FieldValue.serverTimestamp()})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activeIssue])

    useEffect(() => {
        try {
            setLastSeen(getHumanReadableDate(subscriptionsData.filter(i => i.id === issuesQuery.newsletterId)[0].data().lastSeen.toDate()))
        } catch (error) {
            try {
                setLastSeen(getHumanReadableDate(subscriptionsData.filter(i => i.id === issuesQuery.newsletterId)[0].data().lastSeen))
            } catch (error) {
                setLastSeen("Hmm. Unable to tell.")
            }
        }
    },[subscriptionsData, issuesQuery.newsletterId])

    function loadMore() {
        if(moreType.slice(0,4) === 'more') {
            setIssuesQuery({...issuesQuery, more: issuesQuery.more + fetchBatchSize})
        } else {
            setIssuesQuery({...issuesQuery, more: unreadCount + fetchBatchSize})
        }
    }

    document.onkeydown = function (e) {handleKeyDown(e)}
    
    // function iframeBlur(){
    //     window.focus()
    // }
    // setInterval(iframeBlur, 500);

    function resetState () {
        if (activeIssue && activeIssue.data().channelId) {
            const currentTime = vid.getCurrentTime()
            const duration = vid.getDuration()
            if (currentTime > (activeIssue.data().progressSeconds || 0)) {
                activeIssue.ref.update({progressSeconds: currentTime, duration: duration})
            }
            if ((currentTime / duration) > 0.85) {
                archiveOnly()
            }
        }
        setInfoVisible(false)
        setActiveIssue(null)
        setJustSaved(false)
        setJustUnsaved(false)
        setSelectedRow(0)
        setSearchingFolders(false)
        setFolderQuery("")
        setMadeUnread(false)
    }

    function handleKeyDown (e) {
        if (e.keyCode === 27) { //esc
            resetState()
        } else if (activeIssue) {
            if (e.keyCode === 37) { // left arrow
                previousIssue()
            } else if (e.keyCode === 39) { // right arrow
                nextIssue()
            } 
        } else {
            if (e.keyCode === 39) { // right arrow
                if(issues.length > 0) {
                    changeStateForNewIssue(issues[Math.max(0, selectedRow - 1)])
                }
            } else if (e.keyCode === 40) { // down arrow
                if (issues.length === selectedRow) {
                    setSelectedRow(0)
                } else {
                    setSelectedRow(Math.min(issues.length, Math.max(0, selectedRow + 1)))
                }
            } else if (e.keyCode === 38) { // up arrow
                if (selectedRow === 0) {
                    setSelectedRow(issues.length)
                } else {
                    setSelectedRow(Math.min(issues.length, Math.max(0, selectedRow - 1)))
                }
            } 
        }
        // if (subscriptionsData) {
        //     if (e.keyCode === 27) { //esc
        //         resetState()
        //     } else if (e.keyCode === 71 && !searchingFolders) { // g
        //         e.preventDefault()
        //         setNeedingHelp(false)
        //         openFolderSearch()
        //     } else if (e.keyCode === 72 && needingHelp) { // h
        //         setNeedingHelp(false)
        //     } else if (e.keyCode === 72 && !searchingFolders) { // h
        //         setNeedingHelp(true)
        //     } else if (activeIssue && !searchingFolders) {
        //         if (e.keyCode === 83 && activeIssue.data().flag !== "save") { // s
        //             saveIssue()
        //         } else if (e.keyCode === 37 || e.keyCode === 75) { // left arrow or k
        //             previousIssue()
        //         } else if (e.keyCode === 39 || e.keyCode === 74) { // right arrow or j
        //             nextIssue()
        //         } else if (e.keyCode === 73) { // i
        //             toggleInfoVisible()
        //         } else if (e.keyCode === 77) { // m
        //             archive()
        //         } else if (e.keyCode === 85) { // u
        //             unArchive()
        //         }
        //     } else if (!searchingFolders) {
        //         if (e.keyCode === 39 || e.keyCode === 13) { // right arrow or enter
        //             if(issues.length > 0) {
        //                 changeStateForNewIssue(issues[Math.max(0, selectedRow - 1)])
        //             }
        //         } else if (e.keyCode === 40 || e.keyCode === 74) { // down arrow or j
        //             if (issues.length === selectedRow) {
        //                 setSelectedRow(0)
        //             } else {
        //                 setSelectedRow(Math.min(issues.length, Math.max(0, selectedRow + 1)))
        //             }
        //         } else if (e.keyCode === 38 || e.keyCode === 75) { // up arrow or k
        //             if (selectedRow === 0) {
        //                 setSelectedRow(issues.length)
        //             } else {
        //                 setSelectedRow(Math.min(issues.length, Math.max(0, selectedRow - 1)))
        //             }
        //         } else if (e.keyCode === 76) { // l
        //             confirmArchiveAll()
        //         }
        //     } else if (searchingFolders) {
        //         if ((e.keyCode === 39 || e.keyCode === 13) && selectedIdForNavigation !== "") { // right arrow or enter
        //             if(selectedIdForNavigation !== "") {
        //                 document.getElementById(selectedIdForNavigation).click()
        //                 resetState()
        //             }
        //         }
        //     }
        // }
    }

    function saveIssue () {
        setJustSaved(true)
        setJustUnsaved(false)
        activeIssue.ref.update({saveType: activeIssue.data().channelId && 'video' || 'mail', savedAt: firebase.firestore.FieldValue.serverTimestamp()})            
    }

    function unsaveIssue() {
        setJustSaved(false)
        setJustUnsaved(true)
        activeIssue.ref.update({saveType: ''})            
    }

    function previousIssue() {
        const newIssue = issues[issues.indexOf(issues.filter(i => i.id === activeIssue.id)[0]) - 1]
        if(newIssue) {
            changeStateForNewIssue(newIssue)
        } else {
            resetState()
        }
    }

    function nextIssue() {
        const newIssue = issues[issues.indexOf(issues.filter(i => i.id === activeIssue.id)[0]) + 1]
        if(newIssue) {
            changeStateForNewIssue(newIssue)
        } else {
            resetState()
        }
    }

    function archiveOnly () {
        if (activeIssue && activeIssue.data().channelId) {
            const currentTime = vid.getCurrentTime()
            const duration = vid.getDuration()
            if (currentTime > (activeIssue.data().progressSeconds || 0)) {
                activeIssue.ref.update({progressSeconds: currentTime, duration: duration})
            }
        }
        moveFromInbox(userDocSnap.id, activeIssue.data().firestoreNewsletterId, activeIssue.id, "archive")
    }
 
    function archive () {
        archiveOnly()
        nextIssue()
    }

    function unArchive () {
        moveFromInbox(userDocSnap.id, activeIssue.data().firestoreNewsletterId, activeIssue.id, activeIssue.data().channelId && "inbox-video" || "inbox")
        setMadeUnread(true)
    }

    function changeStateForNewIssue(newIssue) {
        setActiveIssue(newIssue)
        setJustSaved(false)
        setJustUnsaved(false)
        setInfoVisible(false)
        setSelectedRow(0)
        setMadeUnread(false)
        window.scrollTo(0, 0)
    }

    function toggleInfoVisible () {
        setInfoVisible(!infoVisible)
    }

    // function openFolderSearch() {
    //     setInfoVisible(false)
    //     setJustSaved(false)
    //     setSearchingFolders(true)
    //     document.getElementById("folder-search-box").focus()
    // }

    function handleFolderSearchChange(e) {
        const query = e.target.value
        setFolderQuery(query)
        const matches = [...folders.filter(i => i.toLowerCase().startsWith(query.toLowerCase())), ...subscriptionsData.filter(i => i.data().newsletterName.toLowerCase().startsWith(query.toLowerCase()))]
        if (matches.length === 0) {
            setSelectedIdForNavigation("")
        } else {
            if (matches[0].name) {
                setSelectedIdForNavigation(encodeURIComponent(matches[0].name)+"-go")
            } else {
                setSelectedIdForNavigation(matches[0].id+"-go")
            }
        }
    }

    function showSidebar () {
        document.getElementById('app-sidebar').scroll(0,0)
        document.getElementById('app-sidebar').classList.add('slide-out-when-mobile')
        document.getElementById('app-main-view').classList.add('hide')
    }

    function archiveAll() {
        const allUnread = subscriptionsData.filter(i => Math.max(0,i.data().inboxCount || 0) > 0)
        const nextSubscription = allUnread[allUnread.indexOf(subscriptionsData.filter(i => i.id === issuesQuery.newsletterId)[0]) + 1]
        const actuallyUnread = issues.filter(i => i.data().flag.includes("inbox")).length
        if (actuallyUnread === 0) {
            subscriptionsData.filter(i => i.id === issuesQuery.newsletterId)[0].ref.update({inboxCount: 0})
        } else {
            for (const issue of issues.filter(i => i.data().flag.includes("inbox"))) {
                moveFromInbox(userDocSnap.id, issue.data().firestoreNewsletterId, issue.id, "archive")
            }
        }
        handleFolderSelection(nextSubscription.id, nextSubscription.data().newsletterName, nextSubscription.data().channelId && "inbox-video" || "inbox")
    }

    function confirmArchiveAll () {
        if (userDocSnap.data().askBeforeArchiveAll) {
            if (window.confirm(`Mark ${issues.filter(i => i.data().flag.includes("inbox")).length} as read in ${issuesQuery.title}?`)) { 
                archiveAll()            
            }
        } else {
            archiveAll()
        }
    }

    function handleFolderSelection(newsletterId, name, flag) {
        if (issuesQuery.newsletterId === newsletterId) {
            resetState()
        } else {
            setLoading(true)
            goToFolder(newsletterId, name, flag)
        }
    }

    function openSettings () {
        setShowingModal(true)
        document.getElementById('newsletter-settings-box').classList.add('modal-slide-down')
    }

    function closeSettings () {
        setShowingModal(false)
        document.getElementById('newsletter-settings-box').classList.remove('modal-slide-down')
    }

    function handleNewFolderTextChange(event) {
        setNewFolderName(event.target.value)
        setAlertMessage("")
    }

    function handleNewPublisherTextChange(event) {
        setNewPublisherName(event.target.value)
        setAlertMessage("")
    }

    function updateNewsletter() {
        setUpdating(true)
        try {
            subscriptionsData.filter(i => i.id === issuesQuery.newsletterId)[0].ref.update({
                newsletterName: newFolderName,
                publisher: newPublisherName
            })
            setIssuesQuery({...issuesQuery, title: newFolderName})
            closeSettings()
        } catch (error) {
            setAlertMessage("There was an error updating the newsletter")
        }
        setUpdating(false)
    }

    // function addToTopFive () {
    //     userDocSnap.ref.update({topPicks: firebase.firestore.FieldValue.arrayUnion(issuesQuery.newsletterId)})
    // }

    function stateChange(e) {
        const currentTime = vid.getCurrentTime()
        const duration = vid.getDuration()
        if (e.data === 2) {
            if (currentTime > (activeIssue.data().progressSeconds || 0)) {
                activeIssue.ref.update({progressSeconds: currentTime, duration: duration})
            }
        } else if (e.data === 0) {
            // video ended
            activeIssue.ref.update({progressSeconds: duration, duration: duration})
            moveFromInbox(userDocSnap.id, activeIssue.data().firestoreNewsletterId, activeIssue.id, "archive")
        }
    }
    
    function playerReady(e, s) {
        e.target.seekTo(s)
        setVid(e.target)
    }

    return (
        <div className="inbox-container">
            {searchingFolders && 
                <div id="folder-search-container">
                    <input id="folder-search-box" type="text" placeholder="Folder or newsletter name" onChange={handleFolderSearchChange} value={folderQuery} autoComplete="off" autoCorrect="off"></input>
                    {folderQuery && folders.filter(i => i.toLowerCase().startsWith(folderQuery.toLowerCase())).map((item, index) =>
                        <Link to="/" onClick={() => goToFolder(null, item, null)} key={item.name} id={encodeURIComponent(item.name)+"-go"}>
                            <InfoRow
                                text={`${characterDictionary[item] || '📁'} ${item}`}
                                noLogo={true}
                                selectedIdForNavigation={selectedIdForNavigation}
                            />
                        </Link>
                    )}
                    {folderQuery && subscriptionsData.filter(i => i.data().newsletterName.toLowerCase().startsWith(folderQuery.toLowerCase())).map((item, index) =>
                        <Link to="/" onClick={() => handleFolderSelection(item.id, item.data().newsletterName, item.data().channelId && "inbox-video" || "inbox")} key={item.id} id={item.id+"-go"}>
                            <InfoRow
                                image={item.data().logo_URL}
                                text={item.data().newsletterName}
                                newsletterId={item.id}
                                pileCount={Math.max(0,item.data().inboxCount || 0)}
                                character={item.data().character}
                                selectedIdForNavigation={selectedIdForNavigation}
                            />
                        </Link>
                    )}
                </div>
            }
            {!activeIssue && issuesQuery &&
                <div className="inbox-header-container">
                    <div className="flex-group">
                        {issuesQuery.newsletterId && <Logo image={subscriptionsData.filter(i => i.id === issuesQuery.newsletterId)[0].data().logo_URL} text={subscriptionsData.filter(i => i.id === issuesQuery.newsletterId)[0].data().newsletterName} color={subscriptionsData.filter(i => i.id === issuesQuery.newsletterId)[0].data().color} character={subscriptionsData.filter(i => i.id === issuesQuery.newsletterId)[0].data().character} />}
                        <div className="cutoff-able-text">
                            <h1 className="title-1 cutoff-able-text">{issuesQuery.title}</h1>
                            <h2 className="subtitle-2 cutoff-able-text hide-when-mobile">{(issuesQuery.newsletterId && subscriptionsData.filter(i => i.id === issuesQuery.newsletterId)[0].data().publisher) || ""}</h2>
                        </div>
                    </div>
                    {issuesQuery.newsletterId && issuesQuery.flag !== "inbox-video" &&
                        <a className="icon-button" onClick={openSettings}><Sliders /></a>
                    }
                    {issuesQuery.title === "Saved" && saveType &&
                        <a className="icon-button" onClick={() => setSaveType(null)}><Filter /></a>
                    }
                </div>
            }
            {!activeIssue && issuesQuery && 
                <div className="mobile-nav show-when-mobile">
                    <a onClick={showSidebar} className="active">
                        <Menu />
                        <span style={{marginLeft:'8px'}}>Folders</span>
                    </a>
                    <a 
                        onClick={(issues.filter(i => i.data().flag.includes("inbox")).length > 0 || (issuesQuery.newsletterId && (subscriptionsData.filter(i => i.id === issuesQuery.newsletterId)[0].data().inboxCount || 0) > 0)) && confirmArchiveAll}
                        className={(issues.filter(i => i.data().flag.includes("inbox")).length > 0 || (issuesQuery.newsletterId && (subscriptionsData.filter(i => i.id === issuesQuery.newsletterId)[0].data().inboxCount || 0) > 0)) && "active"}
                    >
                        <CheckCircle />
                        <span style={{marginLeft:'8px'}}>Mark all read</span>
                    </a>
                </div>
            }
            {activeIssue &&
                <div className="inbox-header-container active-issue">
                    <div className="flex-group hide-when-mobile" style={{flexShrink:0}}>
                        <a className="icon-button" onClick={resetState}><X /></a>
                        <a className="icon-button horizontal-margin" onClick={toggleInfoVisible}><Info /></a>
                        {(justUnsaved || (activeIssue.data().flag !== "save" && !justSaved)) && <a className="icon-button" onClick={saveIssue}><Bookmark/></a>}                            
                        {!justUnsaved && (activeIssue.data().flag === "save" || justSaved) && <a className="icon-button flipped-on" onClick={unsaveIssue}><Bookmark /></a>}
                    </div>
                    <a className="icon-button show-when-mobile" onClick={resetState}><ChevronLeft /></a>
                    <div className='two-line-title cutoff-able-text horizontal-margin'>
                        <a className="title-3 cutoff-able-text" onClick={() => handleFolderSelection(activeIssue.data().firestoreNewsletterId, subscriptionsData.filter(i => i.id === activeIssue.data().firestoreNewsletterId)[0].data().newsletterName, activeIssue.data().channelId && "inbox-video" || "inbox")}>{activeIssue.data().newsletterName || activeIssue.data().channelName || issuesQuery.title}</a>
                        <h2 className="subtitle-3 cutoff-able-text">{activeIssue.data().subject || activeIssue.data().title || ""}</h2>
                    </div>
                    <div className="flex-group hide-when-mobile" style={{flexShrink:0}}>
                        <a className="icon-button" onClick={previousIssue}><ChevronLeft /></a>
                        <a className="icon-button horizontal-margin" onClick={nextIssue}><ChevronRight /></a>
                        {(activeIssue.data().flag.includes("inbox") || madeUnread) && <a className="icon-button" onClick={archive}><CheckCircle /></a>}
                        {activeIssue.data().flag === "archive" && !madeUnread && <a className="icon-button" onClick={unArchive}><RotateCcw /></a>}
                    </div>
                    <a className="icon-button show-when-mobile" onClick={toggleInfoVisible}><MoreHorizontal /></a>
                </div>
            }
            {activeIssue &&
                <div className="mobile-email-footer show-when-mobile">
                    {!activeIssue.data().channelId && <a className="mobile-change-issue-button" onClick={previousIssue}>PREV</a>}
                    {activeIssue.data().flag.includes("inbox") && <a className="icon-button mobile-mark-as-read-button" onClick={archive}><CheckCircle /></a>}
                    {activeIssue.data().flag === "archive" && <a className="mobile-mark-as-unread-button" onClick={unArchive}><RotateCcw /></a>}
                    {justUnsaved && !justSaved && <a className="icon-button" onClick={saveIssue}><Bookmark/></a>}                            
                    {!justUnsaved && (activeIssue.data().flag === "save" || justSaved) && <a className="icon-button flipped-on" onClick={unsaveIssue}><Bookmark /></a>}
                    {!activeIssue.data().channelId && <a className="mobile-change-issue-button" onClick={nextIssue}>NEXT</a>}
                </div>
            }
            {infoVisible && 
                <div className="overlay-info-box email-info-card">
                    <p style={{marginBottom:"8px"}}><span className="emphasized">From:</span> {activeIssue.data().fromAddress || activeIssue.data().channelName}</p>
                    <p style={{marginBottom:"8px"}}><span className="emphasized">Date:</span> {new Date(activeIssue.data().date.toDate()).toLocaleDateString("en-US", {year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</p>
                    <p><span className="emphasized">Subject:</span> {activeIssue.data().subject || activeIssue.data().title}</p>
                </div>
            }
            {loading && issuesQuery.more === 0 &&
                <div className="loading-screen-container">
                    <p className="placeholder-text">Loading inbox...</p>
                </div>
            }
            {(!loading || issuesQuery.more > 0) &&
                <div className="inbox-body-container">
                    {!activeIssue && issues.length > 0 && 
                        <div className="inbox-rows-container">
                            {issues.filter(i => i.id !== instaHidden).map((item, index) => <InboxRow key={item.id} index={index} selectedRow={selectedRow} title={issuesQuery.title} issue={item} setActiveIssue={setActiveIssue} userId={userDocSnap.id} setInstaHidden={setInstaHidden} subscription={subscriptionsData.filter(i => i.id === item.data().firestoreNewsletterId)[0]} />)}
                        </div>
                    }
                    {!activeIssue && issues.length === 0 && (issuesQuery.title !== "Saved" || issuesQuery.title === "Saved" && saveType) &&
                        <div className="empty-state-container">
                            <div style={{textAlign: "center"}}>
                                <img src={emptyStateLogo} alt="Stoop logo" />
                                <p className="placeholder-text" style={{marginTop:"12px"}}>No current issues</p>
                                {lastSeen && <p className="placeholder-text">Last seen: {lastSeen}</p>}
                            </div>
                        </div>
                    }
                    {!activeIssue && issues.length === 0 && issuesQuery.title === "Saved" && !saveType &&
                        <div className="save-type-picker-container">
                            <div><a onClick={() => setSaveType("mail")}><Mail /> Issues</a></div>
                            <div><a onClick={() => setSaveType("video")}><Youtube />Videos</a></div>
                        </div>
                    }
                    {!activeIssue && moreType && 
                        <div>
                            {loading && <p className="link-button-inactive">Loading...</p>}
                            {!loading && moreType !== "premium" && <a className="link-button" onClick={loadMore}>Load more</a>}
                            {!loading && moreType === 'premium' && <p className="link-button-inactive">Loading more requires Stoop Premium</p>}
                        </div>
                    }
                    {activeIssue && activeIssue.data().bodyHTML &&
                        <iframe tabIndex="-1" className="email-iframe" onLoad="scroll(0,0);" srcDoc={activeIssue.data().bodyHTML.replace('<head>', '<head><base target="_blank">')} width={"100%"} />
                    }
                    {activeIssue && activeIssue.data().channelId &&
                        <YouTube
                            ref={vid}
                            videoId={activeIssue.id}
                            id={"ytplayer"}            
                            className={"video-iframe"}
                            containerClassName={"video-container"}
                            opts={{playerVars:{
                                autoplay: 1,
                                rel: 0,
                                modestbranding: 1,
                                origin: 'https://stoopinbox.com',
                            }}}
                            onReady={e => playerReady(e, activeIssue.data().progressSeconds || 0)}
                            // onPause={func}
                            // onEnd={func}
                            onStateChange={e => stateChange(e)}
                        />
                    }
                    {showingModal && <div className="modal-background"><a className="icon-button" onClick={closeSettings}><X /></a></div>}
                    {!activeIssue && issuesQuery.newsletterId && // will soon change this so that you can also change custom folder names
                        <div className="modal-container" id="newsletter-settings-box">
                            <label className='form-label'>Newsletter name</label>
                            <input type="text" className='form-input' value={newFolderName} onChange={handleNewFolderTextChange} autoCorrect="off" autoCapitalize="none" />                    
                            <label className='form-label'>Publisher</label>
                            <input type="text" className='form-input' value={newPublisherName} onChange={handleNewPublisherTextChange} autoCorrect="off" autoCapitalize="none" />                    
                            <button onClick={updateNewsletter} id="request-reset-button" style={{opacity: ((updating || (newFolderName === issuesQuery.title && newPublisherName === ((issuesQuery.newsletterId && subscriptionsData.filter(i => i.id === issuesQuery.newsletterId)[0].data().publisher) || ''))) && 0.5) || 1}} disabled={updating || (newFolderName === issuesQuery.title && newPublisherName === ((issuesQuery.newsletterId && subscriptionsData.filter(i => i.id === issuesQuery.newsletterId)[0].data().publisher) || ''))} type="submit" className="primary-button">
                                {!updating && "Update Newsletter"}
                                {updating && "Updating..."}
                            </button>
                            {/* <button onClick={addToTopFive} type="submit" className="primary-button">
                                Add to Top 5
                            </button> */}
                            {alertMessage !== '' && <p id="reset-alert-message" className="form-help">{alertMessage}</p>}
                        </div>
                    }
                </div>
            }
        </div>
    )
}   