/* eslint-disable no-mixed-operators */
import React from 'react'
import Logo from './Logo'

export default function InfoRow ({newsletterId, image, text, pileCount, issuesQuery, character, selectedIdForNavigation, noLogo, subtitle}) {
    let classText = "info-row"
    
    if (selectedIdForNavigation === encodeURIComponent(text)+"-go" || selectedIdForNavigation === newsletterId+"-go") {
        classText = "info-row selected"
    } else if (issuesQuery && ((issuesQuery.newsletterId && issuesQuery.newsletterId === newsletterId) || (!issuesQuery.newsletterId && text.includes(issuesQuery.title)))) {
        classText = "info-row highlighted"
    }
    return (
        <div id={newsletterId} className={classText}>
            {!noLogo && <Logo image={image} text={text} size={subtitle && "medium" || "small"} character={character} />}
            <div className="cutoff-able-text">
                <div className="cutoff-able-text" style={{display:'flex'}}>
                    <span className={subtitle && "cutoff-able-text info-row-title" || "cutoff-able-text"}>{text}</span>
                    {pileCount > 0 && <span className="pile-count">{pileCount}</span>}
                </div>
                {subtitle && <span className="cutoff-able-text info-row-subtitle">{subtitle}</span>}
            </div>

        </div>
    )
}