/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {Menu, CheckCircle, X} from 'react-feather'
import firebase from "firebase/app"
import 'firebase/auth'

export default function Settings ({userDocSnap}) {
    const [askBeforeArchiveAll, setAskBeforeArchiveAll] = useState(false)
    const [homeFolder, setHomeFolder] = useState("")
    const [folders, setFolders] = useState([])
    const [copyText, setCopyText] = useState('Copy')
    const [newAccountEmail, setNewAccountEmail] = useState(userDocSnap.data().email)
    const [newPassword, setNewPassword] = useState("")
    const [currentPassword, setCurrentPassword] = useState("")
    const [newMailFromSelfEmail, setNewMailFromSelfEmail] = useState("")
    const [alertMessage, setAlertMessage] = useState("")
    const [keepLimitSelect, setKeepLimitSelect] = useState(0)
    const [homeFolderSelect, setHomeFolderSelect] = useState("")
    const [confirmArchiveAllSelect, setConfirmArchiveSelect] = useState("")

    function validateEmail (email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function showSidebar () {
        document.getElementById('app-sidebar').classList.add('slide-out-when-mobile')
        document.getElementById('app-main-view').classList.add('hide')
    }

    useEffect(() => {
        setAskBeforeArchiveAll(userDocSnap.data().askBeforeArchiveAll || false)
        setHomeFolder(userDocSnap.data().customHomeFolder || "Unread")
        const customFolders = userDocSnap.data().newsletterFolders || []
        setFolders(["Unread", "Unwatched", ...customFolders])
    },[userDocSnap])

    function copyEmailToClipboard () {
        const el = document.createElement('textarea');                  // Create a <textarea> element
        el.value = `${userDocSnap.data().username}@stoopinbox.com`;     // Set its value to the string that you want copied
        el.setAttribute('readonly', '');                                // Make it readonly to be tamper-proof
        el.style.position = 'absolute';                 
        el.style.left = '-9999px';                                      // Move outside the screen to make it invisible
        document.body.appendChild(el);                                  // Append the <textarea> element to the HTML document
        const selected =            
            document.getSelection().rangeCount > 0                      // Check if there is any content selected previously
            ? document.getSelection().getRangeAt(0)                     // Store selection if found
            : false;                                                    // Mark as false to know no selection existed before
        el.select();                                                    // Select the <textarea> content
        document.execCommand('copy');                                   // Copy - only works as a result of a user action (e.g. click events)
        document.body.removeChild(el);                                  // Remove the <textarea> element
        if (selected) {                                                 // If a selection existed before copying
            document.getSelection().removeAllRanges();                  // Unselect everything on the HTML document
            document.getSelection().addRange(selected);                 // Restore the original selection
        }
        setCopyText("✓ Copied")
    }

    function logout () {
        firebase.auth().signOut()
        window.location.reload()
    }

    async function saveSettings () {
        const userChanges = {}
        setAlertMessage("Saving changes...")
        try {
            if (newAccountEmail !== userDocSnap.data().email) {
                if (validateEmail(newAccountEmail)) {
                    Object.assign(userChanges, {email: newAccountEmail, personalEmails: firebase.firestore.FieldValue.arrayUnion(newAccountEmail)})
                } else {
                    setAlertMessage("The address in the account email field is invalid")
                    return
                }
            }
            if (Object.keys(userChanges).length > 0 || newPassword.length > 0) {
                const credential = firebase.auth.EmailAuthProvider.credential(firebase.auth().currentUser.email, currentPassword)
                await firebase.auth().currentUser.reauthenticateWithCredential(credential)
                if (newAccountEmail !== userDocSnap.data().email) {
                    await firebase.auth().currentUser.updateEmail(newAccountEmail)
                }
                if (newPassword.length > 0) {
                    await firebase.auth().currentUser.updatePassword(newPassword)
                }
            }
            if (newMailFromSelfEmail.length > 0) {
                if (validateEmail(newMailFromSelfEmail)) {
                    Object.assign(userChanges, {personalEmails: firebase.firestore.FieldValue.arrayUnion(newMailFromSelfEmail)})
                } else {
                    setAlertMessage("The address in the mail to self field is invalid")
                    return
                }
            }
            if (keepLimitSelect > 0 && parseInt(keepLimitSelect) !== (userDocSnap.data().globalKeepLimit || 10)) {
                Object.assign(userChanges, {globalKeepLimit: parseInt(keepLimitSelect)})
            }
            if (homeFolderSelect !== "" && homeFolderSelect !== homeFolder) {
                Object.assign(userChanges, {customHomeFolder: homeFolderSelect})
            }
            if ((confirmArchiveAllSelect === "true" && !askBeforeArchiveAll) || (confirmArchiveAllSelect === "false" && askBeforeArchiveAll)) {
                Object.assign(userChanges, {askBeforeArchiveAll: !askBeforeArchiveAll})
            }
            if (Object.keys(userChanges).length > 0) {
                console.log("Firestore stuff: ", userChanges)
                await userDocSnap.ref.update(userChanges)
            }
        } catch (error) {
            if (error.message.search("password") !== -1) {
                setAlertMessage("Invalid password")
            } else {
                setAlertMessage(error.message)
            }
            return
        }
        setAlertMessage("Saved")
    }

    async function deleteMailFromSelfEmail (email) {
        setAlertMessage("Saving changes...")
        await userDocSnap.ref.update({personalEmails: firebase.firestore.FieldValue.arrayRemove(email)})
        setAlertMessage("")
    }

    return (
        <div className="inbox-container">
            <div className="mobile-nav show-when-mobile">
                <a onClick={showSidebar} className="active">
                    <Menu />
                    <span style={{marginLeft:'8px'}}>Folders</span>
                </a>
                <a>
                    <CheckCircle />
                    <span style={{marginLeft:'8px'}}>Mark all read</span>
                </a>
            </div>
            <div className="inbox-header-container">
                <h1 className="title-1 cutoff-able-text">Settings</h1>
                <a onClick={logout}>Logout</a>
            </div>
            <div className="settings-body-container">
                <h2 className="title-2">Stoop email</h2>
                <p style={{fontWeight:'bold'}}>{`${userDocSnap.data().username}@stoopinbox.com`}</p>
                <a onClick={copyEmailToClipboard}>{copyText}</a>
                <h2 className="title-2">Account login</h2>
                <label className='form-label'>Account email (edit to change)</label>
                <input type="text" placeholder="Email" className='form-input' value={newAccountEmail} onChange={e => setNewAccountEmail(e.target.value)} autoCorrect="off" autoCapitalize="none" />
                <label className='form-label'>New password (edit to change)</label>
                <input type="password" placeholder="Password" className='form-input' value={newPassword} onChange={e => setNewPassword(e.target.value)} />  
                <label className='form-label'>Current password (to authorize changes)</label>
                <input type="password" placeholder="Password" className='form-input' value={currentPassword} onChange={e => setCurrentPassword(e.target.value)} />    
                <h2 className="title-2">App settings</h2>
                <label className='form-label'>Mail from self emails</label>
                <ul>
                    {userDocSnap.data().personalEmails.map((item, index) => 
                        <li key={index} className="flex-group">
                            {item}
                            {item !== userDocSnap.data().email && <a style={{margin:'0 0 0 6px', padding:0}} onClick={() => deleteMailFromSelfEmail(item)}><X color="red" size="16px"/></a>}
                        </li>
                    )}
                </ul>
                <input type="text" placeholder="New from self email" className='form-input' value={newMailFromSelfEmail} onChange={e => setNewMailFromSelfEmail(e.target.value)} autoCorrect="off" autoCapitalize="none" />    
                <label className='form-label'>Default keep limit</label>
                <select id="keepLimitSelect" onChange={(e) => setKeepLimitSelect(e.target.value)} defaultValue={(userDocSnap.data().globalKeepLimit || 10).toString()}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="Infinity">Unlimited</option>
                </select>
                <label className='form-label'>Home folder</label>
                {folders.length > 0 && 
                    <select id="homeFolderSelect" onChange={(e) => setHomeFolderSelect(e.target.value)} defaultValue={homeFolder}>
                        {folders.map((item, index) => 
                            <option key={index} value={item}>{item}</option>
                        )}
                    </select>
                }
                <label className='form-label'>Confirm before archive all?</label>
                <select id="askBeforeArchiveSelect" onChange={(e) => setConfirmArchiveSelect(e.target.value)} defaultValue={(userDocSnap.data().askBeforeArchiveAll || true).toString()}>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </select>
                <p className="form-help" style={{color: alertMessage.toLowerCase().includes("sav") && "green"}}>{alertMessage}</p>
                {
                    (
                        newAccountEmail !== userDocSnap.data().email ||
                        newPassword.length > 0 ||
                        newMailFromSelfEmail.length > 0 ||
                        (keepLimitSelect > 0 && parseInt(keepLimitSelect) !== (userDocSnap.data().globalKeepLimit || 10)) ||
                        (homeFolderSelect !== "" && homeFolderSelect !== homeFolder) ||
                        (confirmArchiveAllSelect === "true" && !askBeforeArchiveAll) ||
                        (confirmArchiveAllSelect === "false" && askBeforeArchiveAll)
                    ) && alertMessage !== "Saved" &&
                    <button className="primary-button" onClick={saveSettings}>Save</button>
                }
            </div>
        </div>
    )
}