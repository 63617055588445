export function getHumanReadableDate (date) {
    if (date === "" || date === null || date === undefined) {
        return "never"
    }
    const evaluateDate = new Date(date)
    const today = new Date()
    let yesterday = new Date()
    let lastWeek = new Date()

    yesterday = new Date(yesterday.setDate(yesterday.getDate() - 1))
    lastWeek = new Date(lastWeek.setDate(lastWeek.getDate() - 7))
    
    if (evaluateDate.toLocaleDateString() === today.toLocaleDateString()) {
        let timeString = evaluateDate.toLocaleTimeString('en-US', {hour:"numeric", minute:"numeric"}).replace(/(:\d{2})$/, "")
        if (timeString.slice(0,2) === "00") {
            timeString = "12"+timeString.slice(2)
        } else if (timeString.slice(0,1) === "0") {
            timeString = timeString.slice(1)
        }
        if (timeString.slice(timeString.length - 1).toUpperCase() !== "M" && evaluateDate.toLocaleTimeString({hour12:false}).slice(0,2) < 12) {
            timeString = timeString+" AM"
        } else if (timeString.slice(timeString.length - 1).toUpperCase() !== "M") {
            timeString = timeString+" PM"
        }
        return timeString
    } else if (evaluateDate.toLocaleDateString() === yesterday.toLocaleDateString()) {
        return "Yesterday"
    } else if (evaluateDate > lastWeek) {
        return evaluateDate.toLocaleDateString("en-US", {weekday: 'long'})
    } else {
        return evaluateDate.toLocaleDateString("en-US", {year: 'numeric', month: 'numeric', day: 'numeric'})
    }
}

export async function moveFromInbox(userId, newsletterId, messageId, toLocation) {
    fetch('https://us-central1-stoop-id.cloudfunctions.net/moveFromInbox', {
        method: 'POST',
        headers: {'Content-Type' : 'application/json'},
        body: JSON.stringify({
            userId: userId,
            newsletterId: newsletterId,
            messageId: messageId,
            toLocation: toLocation
        })
    })
}

export async function moveFromInboxWithoutChangingFreshness(userId, newsletterId, messageId, toLocation) {
    fetch('https://us-central1-stoop-id.cloudfunctions.net/moveFromInboxWithoutChangingFreshness', {
        method: 'POST',
        headers: {'Content-Type' : 'application/json'},
        body: JSON.stringify({
            userId: userId,
            newsletterId: newsletterId,
            messageId: messageId,
            toLocation: toLocation
        })
    })
}