/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom'
import SettingsPage from './Settings'
import Merge from './Merge'
import StoreLogo from './StoreLogo'
import Inbox from './Inbox'
import NotFoundPage from './NotFoundPage'
import InfoRow from './InfoRow'
import {X, Settings, Mail, Youtube} from 'react-feather'

export default function AppLayout ({userDocSnap, subscriptionsData}) {
    const [isShowingAll, setIsShowingAll] = useState(false)
    const [activeIssue, setActiveIssue] = useState(null)
    const [folders, setFolders] = useState([])
    const [issuesQuery, setIssuesQuery] = useState({})
    const [totalUnreadCount, setTotalUnreadCount] = useState(0)
    const [totalUnwatchedCount, setTotalWatchedCount] = useState(0)
    const [atleastTwoTypes, setAtleastTwoTypes] = useState(false)
    const [subscriptionFilter, setSubscriptionFilter] = useState('all')

    function hideSidebar () {
        document.getElementById('app-main-view').classList.remove('hide')
        document.getElementById('app-sidebar').classList.remove('slide-out-when-mobile')
    }

    function toggleEmpty () {
        setIsShowingAll(!isShowingAll)
    }

    // if (!userDocSnap || userDocSnap === "none") {
    //     return null
    // }

    const characterDictionary = {"Unread": '📨', "Unwatched": '📺', "History": '⌛️', "Saved": '❤️'}

    useEffect(() => {
        const defaultFolders = ["Unread", "Unwatched", "History", "Saved"]
        const customFolders = userDocSnap.data().newsletterFolders || []
        setFolders([...defaultFolders, ...customFolders.sort()])
    },[userDocSnap])

    useEffect(() => {
        const customHomeFolder = userDocSnap.data().customHomeFolder || "Unread"
        setIssuesQuery({flag: customHomeFolder === "Unwatched" && 'inbox-video' || 'inbox', title: customHomeFolder, more: 0})
    },[userDocSnap])

    useEffect(() => {
        const totalUreadPlusUnWatched = subscriptionsData.reduce((accumulator, currentValue) => accumulator + (currentValue.data().inboxCount || 0), 0)
        const totalUnwatched = subscriptionsData.filter(i => i.data().type === 'channel').reduce((accumulator, currentValue) => accumulator + (currentValue.data().inboxCount || 0), 0)
        setTotalUnreadCount(totalUreadPlusUnWatched - totalUnwatched)
        setTotalWatchedCount(totalUnwatched)
        const numChannelSubscriptions = subscriptionsData.filter(i => i.data().type === "channel").length
        setAtleastTwoTypes(numChannelSubscriptions > 0 && numChannelSubscriptions < subscriptionsData.length)
    },[subscriptionsData])

    function goToFolder(newsletterId, name, flag) {
        hideSidebar()
        setActiveIssue(null)
        if (name === 'Unread') {
            setIssuesQuery({flag: 'inbox', title: name, more: 0})
        } else if (name === 'Unwatched') {
            setIssuesQuery({flag: 'inbox-video', title: name, more: 0})
        }  else if (name === 'Saved') {
            setIssuesQuery({flag: 'save', title: name, more: 0})
        } else if (name === 'History') {
            setIssuesQuery({flag: 'archive', title: name, more: 0})
        } else if (newsletterId) {
            setIssuesQuery({newsletterId: newsletterId, flag: flag, title: name, more: 0})
        } else {
            setIssuesQuery({title: name, more: 0})
        }
    }

    function resetUI () {
        hideSidebar()
        setActiveIssue(null)
    }
    
    return (
        <Router>
            <div className="app-container">
                <div id="app-sidebar">
                    <div className="sidebar-content">
                        <span className="show-when-mobile">
                            <a className="icon-button" style={{position:'absolute', top:'8px', left:'12px'}} onClick={hideSidebar}><X /></a>
                            <Link to="/settings" className="icon-button" style={{position:'absolute', top:'8px', right:'12px'}} onClick={resetUI}><Settings /></Link>
                            <p className="title-2" style={{margin:"16px 0 8px"}}>Folders</p>
                        </span>
                        <div className="hide-when-mobile"><Link to="/settings" className="icon-button" style={{position:'absolute', top:'24px', left:'24px'}}><Settings /></Link></div>
                        <p className="title-2 hide-when-mobile" style={{margin:"63px 0 8px"}}>Folders</p>
                        {folders.length > 0 && folders.map((item) =>
                            <Link to="/" onClick={() => goToFolder(null, item, null)} key={item}>
                                <InfoRow
                                    text={`${characterDictionary[item] || '📁'} ${item}`}
                                    issuesQuery={issuesQuery}
                                    noLogo={true}
                                    pileCount={item === "Unread" && totalUnreadCount || item === "Unwatched" && totalUnwatchedCount}
                                />
                            </Link>
                        )}
                        <p className="title-2" style={{margin:"16px 0 8px"}}>Subscriptions</p>
                        {atleastTwoTypes &&
                            <div className="folder-filter-container">
                                <a className={subscriptionFilter === 'all' && "filter-selected" || "filter-unselected"} onClick={() => setSubscriptionFilter("all")} disabled={subscriptionFilter === 'all'}>All</a>
                                <a className={subscriptionFilter === 'mail' && "filter-selected" || "filter-unselected"} onClick={() => setSubscriptionFilter("mail")} disabled={subscriptionFilter === 'mail'}><Mail size={18} /></a>
                                <a className={subscriptionFilter === 'videos' && "filter-selected" || "filter-unselected"} onClick={() => setSubscriptionFilter("videos")} disabled={subscriptionFilter === 'videos'}><Youtube size={18} /></a>
                            </div>
                        }
                        {subscriptionsData.filter(i => Math.max(0,i.data().inboxCount || 0) > 0).map((item) =>
                            (subscriptionFilter === 'all' || (subscriptionFilter === 'mail' && item.data().type !== 'channel') || (subscriptionFilter === 'videos' && item.data().type === 'channel')) &&
                            <Link to="/" onClick={() => goToFolder(item.id, item.data().newsletterName, item.data().type && item.data().type === "channel" && "inbox-video" ||"inbox")} key={item.id}>
                                <InfoRow
                                    image={item.data().logo_URL}
                                    text={item.data().newsletterName}
                                    newsletterId={item.id}
                                    pileCount={Math.max(0,item.data().inboxCount || 0)}
                                    character={item.data().character}
                                    issuesQuery={issuesQuery}
                                    subtitle={item.data().type === "channel" && "YouTube Channel" || item.data().publisher}
                                />
                            </Link>
                        )}
                        {subscriptionsData.filter(i => Math.max(0,i.data().inboxCount || 0) > 0).length === 0 && <p style={{marginTop:'10px'}}><span role="img" aria-label="gold star">⭐️</span>All caught up</p>}
                        <p className="title-2" style={{margin:"16px 0 8px"}}>
                            Other Subscriptions (
                            {isShowingAll && <span onClick={toggleEmpty} className="subscriptions-toggle-button">hide</span>}
                            {!isShowingAll && <span onClick={toggleEmpty} className="subscriptions-toggle-button">show</span>}
                            )
                        </p>
                        {isShowingAll && subscriptionsData.filter(i => Math.max(0,i.data().inboxCount || 0) === 0).map((item) =>
                            (subscriptionFilter === 'all' || (subscriptionFilter === 'mail' && item.data().type !== 'channel') || (subscriptionFilter === 'videos' && item.data().type === 'channel')) &&
                            <Link to="/" onClick={() => goToFolder(item.id, item.data().newsletterName, "archive")} key={item.id}>
                                <InfoRow
                                    image={item.data().logo_URL}
                                    text={item.data().newsletterName}
                                    newsletterId={item.id}
                                    pileCount={Math.max(0,item.data().inboxCount || 0)}
                                    character={item.data().character}
                                    issuesQuery={issuesQuery}
                                    subtitle={item.data().type === "channel" && "YouTube Channel" || item.data().publisher}
                                />
                            </Link>
                        )}
                    </div>
                </div>
                <div id="app-main-view">
                    <Switch>
                        <Route path='/settings' render={() => <SettingsPage userDocSnap={userDocSnap} />}/>
                        <Route path='/merge' render={() => <Merge userDocSnap={userDocSnap} />}/>
                        <Route path='/logo' render={() => <StoreLogo userDocSnap={userDocSnap} />}/>
                        <Route path='/' render={() => <Inbox issuesQuery={issuesQuery} setIssuesQuery={setIssuesQuery} userDocSnap={userDocSnap} subscriptionsData={subscriptionsData} activeIssue={activeIssue} setActiveIssue={setActiveIssue} folders={folders} goToFolder={goToFolder} />}/>
                        <Route component={NotFoundPage} />
                    </Switch>
                </div>
                {/* <div id="fixed-footer" className="hide-when-mobile">Tell your friends you use Stoop! Type 'h' for help.</div> */}
            </div>
        </Router>
    )
}